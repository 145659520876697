import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      isLogin:false,
    paramEstInput:{
      R:500000,
      mh:300,
      h2:50000,
      h1:20000,
      v3:500,
      Cd0:0.25,
      Isp:250,
      k:4,
      lambd:2.6,
      aen:0.1,
      N:0.1,
      p0:40000,
      pf:1750,
      z:0.86,
      Lp:0.7,
      tail_style:0,
      waveHalfAngle: 20,
    },
    paramEstOutput:{
      m0:0.0,
      mf:0.0,
      dm:0.0,
      t01:0.0,
      theta1:0.0,
      theta0:0.0,
      v1:0.0,
      v2:0.0,
      R23:0.0,
      uk:0.0,
      P0:0.0,
      Len:0.0,
      Sen:0.0
    },
    aeroAnalyzParam:{},
    unityAeroParam:{},
    aeroAnalyzRet:{
      ClMa:0.0,
      Clalpha:0.0,
      Clalpha2:0.0,
      Cd1Ma:0.0,
      Cd1alpha:0.0,
      Cd1alpha2:0.0,
      Cd20:0.0,
      Cd2Ma:0.0,
      Cd2alpha:0.0,
      Cd2alpha2:0.0
    },
    phase1SimDatas:{
      ridus:20,
      phase:[],
      tArr:[],
      hArr:[],
      lArr:[],
      vArr:[],
      alpha:[],
      theta:[],
        waveHalfAngle:20,//zyj 0611增加
        mainEngineCloseTime:40 //zyj 0611增加
    },
    phase2SimDatas:{
      ridus:20,
      phase:[],
      tArr:[],
      hArr:[],
      lArr:[],
      vArr:[],
      alpha:[],
      theta:[],
        waveHalfAngle:20,//zyj 0611增加
        mainEngineCloseTime:40 //zyj 0611增加
    },
    phase3SimDatas:{
      ridus:20,
      phase:[],
      tArr:[],
      hArr:[],
      lArr:[],
      vArr:[],
      alpha:[],
      theta:[],
        waveHalfAngle:20,//zyj 0611增加
        mainEngineCloseTime:40 //zyj 0611增加
    },
  },
    getters: {
        getLogin(state){
            return state.isLogin
        },
      paramEstInput: (state) => state.paramEstInput,

    },
  mutations: {
      'LOGIN': (state,login) => {
          state.isLogin = login
      },
    setParamInput(s,p){
      s.paramEstInput = p;
    },
    setParamOutput(s,p){
      s.paramEstOutput = p;
    },
    setAeroParam(s,p){
      s.aeroAnalyzParam = p;
    },
    setUnityAeroParam(s,p){
      s.unityAeroParam = p;
    },
    setAeroanalyzRet(s,p){
      s.aeroAnalyzRet = p;
    },
    setPhase1Datas(s,p){
      s.phase1SimDatas = p;
      //zyj 0611增加
      // 第一个界面设置的参数：waveHalfAngle
      s.phase1SimDatas.waveHalfAngle = s.paramEstInput.waveHalfAngle
      // 关机时间
      s.phase1SimDatas.mainEngineCloseTime = s.paramEstOutput.t01
    },
    setPhase2Datas(s,p){
      s.phase2SimDatas = p;
      //zyj 0611增加
      // 第一个界面设置的参数：waveHalfAngle
      s.phase2SimDatas.waveHalfAngle = s.paramEstInput.waveHalfAngle
      // 关机时间
      s.phase2SimDatas.mainEngineCloseTime = s.paramEstOutput.t01
    },
    setPhase3Datas(s,p){
      s.phase3SimDatas = p;
      //zyj 0611增加
      // 第一个界面设置的参数：waveHalfAngle
      s.phase3SimDatas.waveHalfAngle = s.paramEstInput.waveHalfAngle
      // 关机时间
      s.phase3SimDatas.mainEngineCloseTime = s.paramEstOutput.t01
    }
  },
  actions: {
  },
  modules: {
  }
})
