import router from "../router";
import store from '../store'

const white = ['/login', '/']
router.beforeEach(async (to,from,next) => {
    const isLogin = store.getters.getLogin
    console.log(isLogin)
    if (isLogin) {
        next()
    } else {
        if (white.indexOf(to.path) !== -1) {
            next()
        } else {
            next('/login')
        }
    }

})

router.afterEach(() => {

})
