import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './assets/fonts/iconfont/iconfont.js'
import './assets/fonts/iconfont/iconfont.css'
import './assets/css/xy-main.css'
import './views/permission'
import "@mdi/font/css/materialdesignicons.css";
import * as socketApi from './until/websocket'
// import * as unityFunc from './until/Unity'

Vue.prototype.socketApi = socketApi
// Vue.prototype.unityFunc = unityFunc

Vue.config.productionTip = false


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
