var websock = null;
var global_callback = null;
var killInt = null
var hearTick = null
function initWebSocket(){ //初始化weosocket
  //ws地址
  // var wsuri = "ws://192.168.1.130:18222";
  //阿里云地址
  var wsuri = process.env.VUE_APP_WEBSOCKET // "wss://ss.xaerospace.cn/websocket/";
  websock = new WebSocket(wsuri);
  websock.onmessage = function(e){
    websocketonmessage(e);
  }
  websock.onclose = function(e){
    websocketclose(e);
  }
  websock.onopen = function () {
    websocketOpen();
  }

  //连接发生错误的回调方法
  websock.onerror = function () {
    // console.log("WebSocket连接发生错误");
  }
}

// 实际调用的方法
function sendSock(agentData,callback){
  global_callback = callback;
  if (websock.readyState === websock.OPEN) {
    //若是ws开启状态
    websocketsend(agentData)
  }else if (websock.readyState === websock.CONNECTING) {
    // 若是 正在开启状态，则等待1s后重新调用
    setTimeout(function () {
      sendSock(agentData,callback);
    }, 1000);
  }else {
    // 若未开启 ，则等待1s后重新调用
    setTimeout(function () {
      sendSock(agentData,callback);
    }, 1000);
  }
}

//数据接收
function websocketonmessage(e){
  // console.log(JSON.parse(e.data))
  global_callback(JSON.parse(e.data));
}

//数据发送
function websocketsend(agentData){
  websock.send(JSON.stringify(agentData));
}

//关闭
function websocketclose(e){
  reConnect()
  // console.log("connection closed ：" + e);
}

function reConnect(){
  if(hearTick){
    clearTimeout(hearTick)
    hearTick = null
  }
  killInt = setTimeout(function (){
    initWebSocket()
  },2000)
}
function websocketOpen(e){
  if(killInt){
    clearTimeout(killInt)
    killInt = null
    console.log('重连成功后，kill定时器')
  }
  hearTick = setInterval(function (){
    websock.send({ping:'ping'});
    console.log('ping')
  }, 10000)
  console.log("连接成功："+e);
}

initWebSocket();

export{sendSock}
