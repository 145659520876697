import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

/*const routes = [
  {
    path: '/',
    name: 'index',
    component:() => import("../views/index")
  }
]*/
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import("../views/Login")
  },
  {
    path: '/',
    name: 'Introduce',
    component: () => import('@/views/Introduce')
  },
  {
    path: '/index',
    name: 'index',
    component:() => import("../views/index")
  },
  // {
  //   path: '/tips',
  //   name: 'tips',
  //   component:() => import("../views/tips")
  // }
]

const router = new VueRouter({
  routes
})

export default router
