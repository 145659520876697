<template>
    <router-view></router-view>
</template>

<script>
  export default {
    name: "App"
  };
</script>
<style>
.border-red{
  border: 1px solid red;
}
/*.v-text-field input{*/
/*  !*border: 1px solid white !important;*!*/
/*  color: #00ffff !important;*/
/*}*/
/*.v-input__slot{*/
/*  margin-top: -5px !important;*/
/*  margin-left: -20px !important;*/
/*  min-height: 20px !important;*/
/*  max-height: 30px !important;*/
/*}*/
/*.v-input__control{*/

/*  height: 40px;*/
/*}*/
/*.v-text-field__slot{*/
/*  height: 30px !important;*/
/*}*/
html::-webkit-scrollbar {
  display: none;
}
::v-deep{
  scrollbar-width: none;
  overflow: hidden;
}

</style>
